import { createSlice, createAction } from '@reduxjs/toolkit';

export type ApplicationState = {
  currentCompanyId: number | undefined,
};

const initialState: ApplicationState = {
  currentCompanyId: undefined,
};

// action to update the sync status
export const setCurrentCompanyId = createAction<number | undefined>('providers/setCurrentCompanyId');

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setCurrentCompanyId, (state, action) => {
      state.currentCompanyId = action.payload;
    })
  }
});

export default applicationSlice.reducer;
