
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { Config as GtmConfig, ENABLED as GtmEnabled, } from 'config/gtm';
import { AuthProvider } from '~/context/AuthContext';
import { useValidation } from '~/hooks/validation';
import TagManager from 'react-gtm-module';
import type { AppProps } from 'next/app';
import { RecoilRoot } from 'recoil';
import React, { ReactNode, useEffect } from 'react';
import { setLocale } from 'yup';
import PiwikProProvider from '@piwikpro/next-piwik-pro';
import { PIWIK_CONTAINER_ID, PIWIK_CONTAINER_URL, ENABLED as PiwikEnabled } from 'config/piwik';
import { Provider } from 'react-redux';
import store from '~/store/index';
import '../styles/globals.css';
const Tracking: React.FC<{
    children: ReactNode;
}> = ({ children }) => {
    if (PiwikEnabled && PIWIK_CONTAINER_ID && PIWIK_CONTAINER_URL) {
        return (<PiwikProProvider containerUrl={PIWIK_CONTAINER_URL} containerId={PIWIK_CONTAINER_ID}>
        {children}
      </PiwikProProvider>);
    }
    return <>{children}</>;
};
function MyApp({ Component, pageProps }: AppProps): JSX.Element {
    const { config } = useValidation();
    setLocale(config);
    useEffect(() => {
        if (GtmEnabled && TagManager) {
            TagManager.initialize(GtmConfig);
        }
    }, []);
    return (<Tracking>
      <RecoilRoot>
        <Provider store={store}>
          <AuthProvider>
            <div>
              <div className="text-base antialiased font-body">
                <Component {...pageProps}/>
              </div>
            </div>
          </AuthProvider>
        </Provider>
      </RecoilRoot>
    </Tracking>);
}
const __Next_Translate__Page__1891fecc5a9__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__1891fecc5a9__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  